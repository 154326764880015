<template>
  <div class="w-full flex flex-col space-y-6">
    <!-- Speak To Text -->
    <soundQuestion
      ref="soundQuestion"
      @changeAnswer="(val) => (speakText = val)"
      @load="(val) => (load = val)"
      @resetButtonStep="resetButton()"
      @startValue="startValue"
      @endValue="endValue"
      @isError="(val) => isError(val)"
      @expectedAnswerState="(val) => changeExpectedAnswerState(val)"
    />
    <!-- Speak To Text -->

    <!-- Header Content -->

    <div class="w-full rounded-full flex items-center justify-center">
      <!-- SORU SORMAYA BAŞLADIĞI KISISM  -->
      <asistan :gifActive="gifActive" />
    </div>

    <speechAnimation :botSepeak="botSepeak" :speechReady="speechReady" />

    <textToItem
      :value="isFakeLoad ? '' : getText"
      ref="textItem"
      @startValue="startValue"
      @endValue="endValue"
      @setLoading="(val) => (speechReady = val)"
    />

    <div class="w-full flex justify-center items-center">
      <p
        class="mx-auto text-gray-400 text-[15px] max-md:text-xs text-center"
      >
        {{ formatText(currentQuestion) }}
      </p>
    </div>

    <!-- Header Content -->
  </div>
</template>

<script>
import speechAnimation from "../../components/speechAnimation.vue";
import textToItem from "../../components/textToItem.vue";
import soundQuestion from "../../components/soundQuestion.vue";
import asistan from "@/components/asistans.vue";

export default {
  name: "content-panel",
  props: ["loadingState"],
  components: {
    speechAnimation,
    textToItem,
    soundQuestion,
    asistan,
  },
  data() {
    return {
      gifActive: false,
      speakText: "",
      isCandidate: false,
      erorState: false,
      botSepeak: false,
      load: false,
      speechReady: false,
      isMobileDevice: window.innerWidth <= 768,
      isSoundPlaying: false,
      isFakeLoad: true,
    };
  },
  methods: {
    resetButton() {
      this.$emit("resetStep", true);
    },
    startValue() {
      this.gifActive = true;
      this.$emit("isSounEnded", false);
      this.botSepeak = true;
      this.$emit("isDisabled", true);
      this.isSoundPlaying = true;
    },
    endValue() {
      this.gifActive = false;
      this.$emit("isSounEnded", true);
      this.botSepeak = false;
      this.$emit("isDisabled", false);
    },
    isError(val) {
      this.erorState = val;
      if (val) {
        this.synthesizeSpeech();
      }
    },
    startRecording() {
      this.$refs.soundQuestion.startRecording();
    },
    stopRecording() {
      this.$refs.soundQuestion.stopRecording();
    },

    changeExpectedAnswerState(val) {
      console.log(val);
      if (val.expectedAnswerState == 2 && val.questionState == 2) {
        this.$store.commit("setIsDecision");
      }

      this.$emit("changeAnswer", this.speakText);
      this.$emit("changeExpectedAnswer", val.expectedAnswerState);
      this.$emit("nextStep");
      this.isSoundPlaying = false;
    },

    synthesizeSpeech() {
      setTimeout(() => {
        this.$refs.textItem.synthesizeSpeech();
      }, 50);
    },

    // handlePlayButtonClicked() {
    //   this.$store.commit("setPageReloaded", false);
    //   this.$refs.textItem.playSound();
    // },
  },

  computed: {
    currentQuestion() {
      return this.$store.state.questionsAndAnswer[this.$store.state.answerPage]
        .question;
    },
    getText() {
      const { speechUrl, question, type } =
        this.$store.state.questionsAndAnswer[this.$store.state.answerPage];
      if (!this.erorState) {
        let data = speechUrl
          ? { url: Array.isArray(speechUrl) ? speechUrl[0] : speechUrl }
          : question;
        return data;
      } else {
        if (type == 2 || type == 3) {
          return {
            url: require("@/assets/numberError.mp3"),
          };
          //return " Lütfen istenen bilgiyi net bir sayısal değer olarak belirtin, örneğin '4.500 ' ya da bir aralık olarak '4.000 ila 5.000 '. Eğer paylaşmak istemiyorsanız, 'paylaşmak istemiyorum' şeklinde yanıtlayabilirsiniz.";
        } else if (type == 4) {
          return {
            url: require("@/assets/yesNoError.mp3"),
          };
          //return "Yanıtınız biraz belirsiz görünüyor. Soruya net bir şekilde 'evet' ya da 'hayır' şeklinde cevap verebilir misiniz?";
        }
        return {
          url: require("@/assets/noFormat.mp3"),
          //return " Verdiğiniz yanıt beklenen formatın dışında. Lütfen soruyu dikkatlice okuyup uygun bir şekilde yanıtlayın."
        };
      }
    },
  },
  created() {
    setTimeout(() => {
      this.isFakeLoad = false;
      this.synthesizeSpeech();
    }, 200);
  },
  watch: {
    speakText(val) {
      this.$emit("changeAnswer", val);
    },
    load(val) {
      this.$emit("isLoad", val);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
