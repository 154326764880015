<template>
  <div class="flex items-center relative w-full" style="height: calc(100vh - 58px)">
    <div class="w-8/12 max-lg:w-full  mx-auto h-full text-white border-l border-r border-gray-300">
      <!-- Header - Fixed -->
      <div class="h-[50px] border-b border-gray-500 flex items-center px-5">
        <h1 class="text-xl">Cevaplarınızı İnceleyin</h1>
      </div>

      <!-- Form with scrollable content and fixed footer -->
      <form @submit.prevent="save" class="h-[calc(100%-50px)] flex flex-col">
        <!-- Scrollable Questions List -->
        <div class="flex-1 overflow-y-auto">
          <ul class="w-full">
            <li class="border-b border-gray-800 p-5" v-for="(item, index) in List" :key="index">
              <p>-{{ formatText(item.question) }}</p>
              <input 
                :disabled="item.isEditQuestion == 2" 
                class="w-full py-2 px-3 border bg-transparent border-gray-200 mt-3 mb-5 rounded" 
                :class="item.isEditQuestion == 2 && 'border-gray-600 bg-gray-900 text-gray-400 select-none'"
                type="text" 
                v-model="item.answer" 
              />
              <p class="text-red-500 text-sm" v-if="item.errorMessage">{{ item.errorMessage }}</p>
            </li>
          </ul>
        </div>

        <!-- Fixed Footer -->
        <div class="h-[65px] py-2.5 flex items-center justify-end px-5 border-t border-gray-500 ">
          <button 
            :disabled="load" 
            class="h-full bg-gray-200 px-8 text-black rounded"
          >
            <p v-if="!load">Kaydet</p>
            <p v-else>
              <i class="fas fa-spinner fa-spin mr-1"></i>
              Kaydediliyor...
            </p>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { meetingInterview } from "@/networking/urlmanager";

export default {
  name: "answer-list",
  components: {},
  data() {
    return {
      List: this.$store.state.questionsAndAnswer
        .map((item) => ({
          ...item,
          errorMessage: "",
          isEdit: 1,
          originalAnswer: item.answer,
        }))
        .filter(
          (item, index) =>
            (this.$store.state.answerPage + 2 <=
            this.$store.state.questionsAndAnswer.length
              ? this.$store.state.isDecision
                ? this.$store.state.questionsAndAnswer.length - 2
                : this.$store.state.questionsAndAnswer.length
              : this.$store.state.questionsAndAnswer.length) > index
        ),
      load: false,
    };
  },
  methods: {
    save() {
      const allAnswersValid = this.List.filter((item) => !item.answer);
      if(allAnswersValid.length > 0){
        this.List = this.List.map((item) => {
          const matchedItem = allAnswersValid.find((r) => r.id == item.id);
          if (matchedItem) {
            return { ...item, errorMessage: "Lütfen bu alanı dolurunuz. " };
          } else {
            return item;
          }
        });

        this.notificationAlert(
          "Cevap formatı uygun değil. Lütfen cevabınızı doğru formatta girin ve tekrar deneyin.",
          "error"
        );
      }else {
        this.load = true;
        this.axios.post(meetingInterview.complateMeeting, {
            questionsAndAnswer: this.List,
            token: this.$store.state.token,
            // stateId: 4,
          })
          .then(() => {
            this.List = this.List.map((item) =>  {return { ...item, errorMessage: "" }});
            this.load = false;
            this.$router.push("/interview/thanks/speech");
          })
          .catch((err) => {
            if (err?.response?.data?.data?.length > 0) {
              let data = err.response.data.data;
              this.List = this.List.map((item) => {
                const matchedItem = data.find((r) => r.id == item.id);
                if (matchedItem) {
                  return {
                    ...item,
                    errorMessage: "Verdiğiniz yanıt beklenen formatın dışında. Lütfen soruyu dikkatlice okuyup uygun bir şekilde yanıtlayın",
                  };
                } else {
                  return { ...item, errorMessage: ""}
                }
              });
            }else{
              this.List = this.List.map((item) =>  {return { ...item, errorMessage: "" }});
            }
            this.notificationAlert(err, "error");
            this.load = false;
          });
      }
    },
  },
  created() {
    if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
      this.$router.push("/");
    }
  },
  watch: {
    List: {
      deep: true,
      handler(newVal, oldVal) {
        newVal.forEach((item, index) => {
          if (item.answer !== oldVal[index].originalAnswer) {
            item.isEdit = 2;
          } else {
            item.isEdit = 1;
          }
        });
      },
    },
    "$store.state.consentStatement.isValid"(val) {
      if (!val && this.$route.path != "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
