<template>
  <div>
    <div class="w-full h-full flex items-center justify-center">
      <!-- 1. SORU SORMAYA BAŞLADIĞI KISISM  44 bgcol rounded-full-->
      <asistan :gifActive="gifActive" />
    </div>

    <!-- Animation Component -->
    <!-- <speechAnimation :botSepeak="botSepeak" :speechReady="speechReady" /> -->
    <!-- Animation Component -->

    <!-- Speak Component -->
    <textToItem
      :value="getInfo"
      ref="textItem"
      @startValue="startValue"
      @endValue="endValue"
      @requestCompleted="handleRequestCompleted"
      @setLoading="(val) => (speechReady = val)"
    />
    <div class="w-full flex justify-center items-center p-5">
      <transition name="fade" mode="out-in">
        <p
          class="mx-auto text-gray-400 text-[15px] max-md:text-xs max-sm:w-10/12 text-center"
        >
          {{ getInfo.text }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import textToItem from "../../components/textToItem.vue";
// import speechAnimation from "../../components/speechAnimation.vue";
import asistan from "@/components/asistans.vue";

export default {
  name: "content-panel",
  components: {
    // speechAnimation,
    textToItem,
    asistan,
  },
  data() {
    return {
      gifActive: false,
      botSepeak: false,
      isLoading: false,
      speechReady: false,
      isSoundPlaying: false,
    };
  },
  computed: {
    getInfo() {
      const { speechUrl, text } = this.$store.state.infoStartMesage;
      return {
        url: speechUrl,
        text: text,
      };
    },
  },
  methods: {
    handleRequestCompleted() {
      this.isLoading = false;
    },
    startValue() {
      this.gifActive = true;
      this.isSoundPlaying = true;
      this.botSepeak = true;
      this.$emit("nextPage", false);
    },
    endValue() {
      this.gifActive = false;
      this.$emit("nextPage", true);
      this.botSepeak = false;
    },
    // handlePlayButtonClicked() {
    //   this.isSoundPlaying = true;
    //   this.$refs.textItem.playSound();
    // },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
