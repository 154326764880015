<template>
  <div class="w-full  px-4 py-6 sm:py-12 overflow-y-auto">
    <div class="w-full flex flex-col items-center space-y-8">
      <!-- Mic Type Modal -->
      <micTypeModal ref="micTypeModal" @result="handleMicTypeResult" />
      
      <!-- Start Button Section -->
      <div class="w-full max-w-md flex flex-col justify-center items-center space-y-6">
        
        <button data-v-step="0" @click="start" :disabled="selectedMicType === null" class="h-32 w-32 sm:h-44 sm:w-44 animation-pulse bg-white rounded-full pulse textcol text-base sm:text-xl flex items-center justify-center mx-auto transition-all duration-300 hover:scale-105" >
          Başlat
        </button>

        <p class="mt-4 sm:mt-10 w-11/12 sm:w-8/12 mx-auto text-gray-300 text-sm sm:text-base text-center">
          <span class="text-base sm:text-lg font-semibold underline">NOT:</span>
          Ön görüşmenin gerçekleşebilmesi için cihazınızın
          <b>sesinin açık olduğuna emin olun</b>
        </p>

        <div class="flex items-center justify-center pt-3 sm:pt-5 text-xs gap-2 sm:gap-3 px-4">
          <input v-model="$store.state.consentStatement.isValid" type="checkbox" class="w-4 h-4 sm:w-5 sm:h-5 cursor-pointer" @change="chanceIsValid" />
          <a class="text-white underline text-xs sm:text-sm break-words" :href="$store.state.consentStatement.url" target="_blank">
            Açık Rıza Beyanını onaylıyorum.
          </a>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import micTypeModal from "./micTypeModal.vue";
export default {
  name: "start-btn",
  components: {
    micTypeModal,
  },
  data() {
    return {
      isValid: this.$store.state.consentStatement.isValid,
      selectedMicType: null,
    };
  },
  methods: {
    handleMicTypeResult(type) {
      this.selectedMicType = type;
    },
    async start() {
      if (this.$store.state.consentStatement.isValid) {
        
        if (this.selectedMicType === null) {
          return;
        }

        this.$store.commit("setMicActiveType", this.selectedMicType);
        try {
          let stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          if (stream.active) {
            this.$router.push("/interview/inform");
          } else {
            throw new Error("Mikrofon aktif değil");
          }
        } catch (err) {
          console.error(err)
          this.authController("Lütfen mikrofon iznini onaylayın ve mikrofonunuzun aktif olduğundan emin olun.");
        }
      } else {
        this.authController("Lütfen Açık Rıza Beyanını Onaylayın.");
      }
    },
    chanceIsValid() {
      this.isValid = !this.isValid;
      this.$store.commit("chanceIsValid", this.isValid);
    },
  },
};
</script>
