<template>
  <div class="h-auto md:min-h-screen-24 flex items-center justify-center">
    <div class=" xl:w-6/12">
      <div>
        <!-- <success v-if="isOk" /> -->
        <datePicker @isOk="toggleIsOk" />
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "./components/datePicker.vue";
// import success from "./components/success.vue";

export default {
  name: "InterviewDatePage",
  components: {
    datePicker,
    // success,
  },
  data() {
    return {
      selectedDate: null,
      isOk: false,
    };
  },
  methods: {
    toggleIsOk() {
      this.$store.commit("setDateSelected", true);
      this.$router.push("/end");
    },
  },
};
</script>
