<template>
  <div class=" transition-all ease-in-out">
    <div class="w-full h-full justify-center py-2">
      <startBtn v-if="!load" />
      <loadingItem v-else :load="load" />
    </div>
    <tour :steps="steps" v-if="!load" />
  </div>
</template>

<script>
import tour from "@/components/tour.vue";

// local component
import loadingItem from "./components/loading.vue";
import startBtn from "./components/start.vue";
import axios from "axios";

// networking
import { meetingInterview } from "@/networking/urlmanager";
export default {
  name: "interviewPage",
  components: {
    tour,
    loadingItem,
    startBtn,
  },
  data() {
    return {
      load: true,
      steps: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: "Ön Görüşmeyi başlat",
          },
          content:
            'Ön görüşmeye başlamak için lütfen "BAŞLA" butonuna tıklayın.',
          params: {
            placement: "top",
          },
        },
      ],
    };
  },
  methods: {
    async verify() {
      try {
        this.load = true;
        const res = await axios.get(meetingInterview.verify, {
          params: {
            token: this.$route.query.token,
          },
          headers: {
            Authorization: "Bearer " + this.$route.query.token,
          },
        });
        const { companyName, fullname, info, consentStatement, position, question } = res.data.data;
        this.$store.commit("interviewQuestions", {
          questions: question,
          infoStartMesage: info,
          user: fullname,
          company: companyName,
          token: this.$route.query.token,
          position: position,
          consentStatement: consentStatement,
          isPreliminaryInterview: true,
        });

        this.load = false;
      } catch (error) {
        this.$router.push("/end");
      }
    },
    changeMicrophoneInteraction() {
      if (this.clickRelease) {
        // Handle click-release interaction
      }
      if (this.pressHold) {
        // Handle press-hold interaction
      }
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  mounted() {
    if (this.$store.state.isPreliminaryInterview == false) {
      this.verify();
    } else this.load = !this.$store.state.isPreliminaryInterview;

    if ( !this.$store.state.consentStatement.isValid && this.$route.path != "/") {
      this.$router.push("/");
    }
  },
  watch: {
    "$store.state.consentStatement.isValid"(val) {
      if (!val && this.$route.path != "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.master {
  color: black;
  float: right;
}
@media (max-width: 900px) {
  .master {
    display: none;
  }
}
</style>
